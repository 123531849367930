import React, {useEffect} from 'react';
import {Carousel, Container} from "react-bootstrap";
import "./Slider.scss"
import {useAnimation, motion} from "framer-motion";
import {useInView} from "react-intersection-observer";

const Slider = () => {

    const animation = useAnimation()
    const [aboutRef, inView] = useInView({
        triggerOnce: true,
        // Giving our scrollwheel additional 300px before executing animation
        // rootMargin: "-300px",
    })

    useEffect(() => {
        if (inView) {
            animation.start("visible")
        }
    }, [animation, inView])

    return (
        <>
            <Carousel controls={false}>
                <Carousel.Item>
                    <img
                        src="/images/header/slider/carousel-image1.jpg"
                        alt="First slide"
                    />
                    {/*<video style={{transform: "scale(1.007)"}} loop muted autoPlay playsInline*/}
                    {/*       // poster="tu url do zdj"*/}
                    {/*>*/}
                    {/*    <source src="/images/header/header-video.mp4" type="video/mp4"/>*/}
                    {/*    Sorry, your browser doesn't support videos.*/}
                    {/*</video>*/}
                    <Container>
                        <Carousel.Caption>
                            <motion.div
                                className="display-4 text-uppercase text-secondary fw-bolder "
                                ref={aboutRef}
                                animate={animation}
                                initial="hidden"
                                variants={{
                                    visible: {
                                        clipPath: 'polygon(0% 0%,100% 0%,100% 100%,0% 100%)',
                                        transition: {duration: 1.4, ease: "easeOut"},
                                    },
                                    hidden: {
                                        clipPath: 'polygon(0% 0%,100% 0%,100% 0%,0% 0%)',
                                    },
                                }}
                            >
                                Spotkania pełne smaku
                            </motion.div>
                            <motion.div
                                className="display-4 text-uppercase text-secondary fw-bolder desc"
                                ref={aboutRef}
                                animate={animation}
                                initial="hidden"
                                variants={{
                                    visible: {
                                        clipPath: 'polygon(0% 0%,100% 0%,100% 100%,0% 100%)',
                                        transition: {duration: 1.4, ease: "easeOut"},
                                    },
                                    hidden: {
                                        clipPath: 'polygon(0% 0%,100% 0%,100% 0%,0% 0%)',
                                    },
                                }}
                            >
                                #spotkajmysiewmeatme
                            </motion.div>
                        </Carousel.Caption>
                    </Container>
                </Carousel.Item>

                {/*<Carousel.Item>*/}
                {/*    <img*/}
                {/*        src="/images/header/slider/carousel-image2.jpg"*/}
                {/*        alt="First slide"*/}
                {/*    />*/}
                {/*    <Container>*/}
                {/*        <Carousel.Caption>*/}
                {/*            <div className="display-4 text-uppercase text-secondary fw-bolder">*/}
                {/*                Spotkania pełne smaku*/}
                {/*            </div>*/}
                {/*            <div className="display-4 text-uppercase text-secondary fw-bolder">*/}
                {/*                #spotkajmysiewmeatme*/}
                {/*            </div>*/}
                {/*        </Carousel.Caption>*/}
                {/*    </Container>*/}
                {/*</Carousel.Item>*/}

                {/*<Carousel.Item>*/}
                {/*    <img*/}
                {/*        src="/images/header/slider/carousel-image3.jpg"*/}
                {/*        alt="First slide"*/}
                {/*    />*/}
                {/*    <Container>*/}
                {/*        <Carousel.Caption>*/}
                {/*            <div className="display-4 text-uppercase text-secondary fw-bolder">*/}
                {/*                Spotkania pełne smaku*/}
                {/*            </div>*/}
                {/*            <div className="display-4 text-uppercase text-secondary fw-bolder">*/}
                {/*                #spotkajmysiewmeatme*/}
                {/*            </div>*/}
                {/*        </Carousel.Caption>*/}
                {/*    </Container>*/}
                {/*</Carousel.Item>*/}


            </Carousel>
        </>
    );
};

export default Slider;