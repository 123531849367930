import React from 'react';
import {Col, Container, Row} from "react-bootstrap";
import {BsFacebook, BsInstagram} from "react-icons/bs";

const ContactSection = () => {
    return (
        <section className="bg-primary">

            <section className="bg-black py-5">
                <Container>

                    <Row>
                        <Col lg={6} className="">
                            <h2 className="text-uppercase text-secondary">Jak dojechać?</h2>
                            <p>
                                adres: Ul. Józefa Marka 12, D.H. NOVA - Szubryt, II piętro, 34-600 Limanowa
                                <br/>
                                telefon: <a href="tel:883844712">883-844-712</a>
                                <br/>
                                email: <a
                                href="mailto:restauracja@restauracjameatme.pl">restauracja@restauracjameatme.pl</a>
                                <br/>
                            </p>

                            <p>
                                wt-czw 12:00-21:00
                                <br/>
                                pt-sob 12:00-00:00
                                <br/>
                                niedz 12:00-21:00
                            </p>

                            {/*<a href="/">www.meatme.pl</a>*/}
                            <div className="my-4">
                                <a target="_blank" rel="noreferrer" href="https://www.facebook.com/restauracjameatme/"><BsFacebook
                                    className="h1 text-secondary me-3"/></a>
                                <a target="_blank" rel="noreferrer" href="https://www.instagram.com/restauracja.meatme/"><BsInstagram
                                    className="h1 text-secondary"/></a>
                            </div>

                        </Col>
                        <Col>

                            <iframe title="lokalizacja" width="100%" height="400"
                                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d10319.268556112833!2d20.401758791970565!3d49.71424422008753!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47161d69e53b5d73%3A0x94fb755a4d9e3493!2sRestauracja%20MEAT%20ME!5e0!3m2!1spl!2spl!4v1655220512072!5m2!1spl!2spl"
                                    frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0">
                            </iframe>


                        </Col>
                    </Row>

                </Container>
            </section>

        </section>
    );
};

export default ContactSection;