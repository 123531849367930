import React from "react";
import {Root} from './App';
import ReactDOM from "react-dom/client";


const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <React.StrictMode>
        <Root/>
    </React.StrictMode>
);