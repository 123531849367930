import React, {useState} from 'react';
import {Nav, Offcanvas} from "react-bootstrap";
import {MenuData} from "./MenuData";
import {useLocation, useNavigate} from "react-router-dom";

const MenuMobile = () => {

    const [open, setOpen] = useState(false)
    let navigate = useNavigate();
    let location = useLocation();

    const ScrollTo = (id) => {
        const redirect = () => {
            if (location.pathname !== "/") navigate("/")
        }

        async function asyncCall() {
            await redirect();

            setTimeout(() => {
                const yOffset = -95;
                const element = document.getElementById(id);
                const y = element.getBoundingClientRect().top + window.scrollY + yOffset;
                window.scrollTo({top: y, behavior: 'smooth'});
            }, location.pathname !== "/" ? 700 : 0);

        }

        asyncCall();
        setOpen(false)
    }

    return (
        <>
            <div
                className="d-flex d-xl-none"
                onClick={() => setOpen(!open)}
            >
                <svg fill="#ECE99A" viewBox="0 0 100 80" width="40" height="40">
                    <rect width="100" height="10"/>
                    <rect y="30" width="100" height="10"/>
                    <rect y="60" width="100" height="10"/>
                </svg>
            </div>

            <Offcanvas scroll={false} backdrop={false} placement="bottom" className="bg-primary text-secondary" show={open} onHide={() => setOpen(false)}>
                <Offcanvas.Body>

                    <Nav defaultActiveKey="/home" className="flex-column" style={{marginTop: "200px"}}>

                        {
                            MenuData.map((i, k) => {
                                return (
                                    <div key={k}>
                                        {i.title === "O nas" || i.title === "Menu" || i.title === "Kuchnia" ?
                                            <div
                                                className="h-100" onClick={() => ScrollTo(i.hrf)}>
                                                <Nav.Link className="h-100 d-flex align-items-center display-1">{i.title}</Nav.Link>
                                            </div> :
                                                <Nav.Link onClick={() => {
                                                    navigate(i.hrf)
                                                    setOpen(false)
                                                }} className="h-100 d-flex align-items-center display-1">{i.title}</Nav.Link>
                                        }
                                    </div>
                                )
                            })
                        }
                    </Nav>
                </Offcanvas.Body>
            </Offcanvas>
        </>
    );
};

export default MenuMobile;