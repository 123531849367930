import React, {useEffect} from 'react';
import {Col, Image, Row} from "react-bootstrap";
import Heading from "../../section-heading/Heading";
import {motion, useAnimation} from "framer-motion";
import {useInView} from "react-intersection-observer";
import Zoom from "react-medium-image-zoom";

const KitchenSection = () => {


    const animation = useAnimation()
    const [aboutRef, inView] = useInView({
        triggerOnce: true,
        rootMargin: "-50px",
    })

    useEffect(() => {
        if (inView) {
            animation.start("visible")
        }
    }, [animation, inView])


    return (
        <section id="kitchen">
            <Heading title="Kuchnia"/>

            <article ref={aboutRef} className="position-relative bg-primary">
                <div style={{contain: "content"}}
                     className="position-absolute section-left start-0 top-0 bottom-0 w-50 d-flex">
                    <Image style={{zIndex: "1"}} className="" src="/images/media/section-frame.jpg"/>

                    <motion.div
                        animate={animation}
                        initial="hidden"
                        variants={{
                            visible: {
                                clipPath: 'polygon(0% 0%,100% 0%,100% 100%,0% 100%)',
                                scale: 1,
                                transition: {duration: 0.7, ease: "easeOut"},
                            },
                            hidden: {
                                clipPath: 'polygon(0% 0%,100% 0%,100% 0%,0% 0%)',
                                scale: 1.1
                            },
                        }}
                    >
                        {/*<Zoom overlayBgColorEnd="#2D523F">*/}
                            <video
                                loop muted autoPlay playsInline
                                className="h-100"
                                // poster="tu url do zdj"
                            >
                                <source src="/images/header/slider/header-video.mp4" type="video/mp4"/>
                                Sorry, your browser doesn't support videos.
                            </video>
                        {/*</Zoom>*/}

                    </motion.div>

                </div>

                <div className="container">
                    <Row>
                        <Col lg={{span: 6, offset: 6}} style={{zIndex: "1", paddingBlock: "4rem"}}
                             className="ps-lg-5">
                            <motion.p
                                className="text-secondary mb-4"
                                animate={animation}
                                initial="hidden"
                                variants={{
                                    visible: {
                                        opacity: 1,
                                        y: 0,
                                        transition: {duration: 0.7, ease: "easeOut"},
                                    },
                                    hidden: {opacity: 0, y: 40},
                                }}
                            >
                                Kulinarny tygiel smaków wypełniony wyjątkową atmosferą. Umiejętne połączenie
                                inspiracji rodem z tradycji polskiej kuchni serwowanej w zmodernizowanym wydaniu z
                                innowacyjnością w kreowaniu dań. Wykwintny smak mięsa najwyższej jakości - w tym
                                sezonowanego, bogatego w walory smakowe, przygotowywanego w żywym ogniu w piecu
                                marki Mibrasa opalanym węglem kubańskim, uzyskiwanym z tropikalnych roślin. To także
                                propozycje dla miłośników dań wege, lekkie desery, orzeźwiające koktajle i drinki na
                                światowym poziomie.
                            </motion.p>


                            <motion.p
                                animate={animation}
                                initial="hidden"
                                variants={{
                                    visible: {
                                        opacity: 1,
                                        y: 0,
                                        transition: {duration: 0.7, ease: "easeOut", delay: 0.35},
                                    },
                                    hidden: {
                                        opacity: 0,
                                        y: 40,
                                    },
                                }}
                            >
                                Pieczę nad kuchnią sprawuje doświadczony szef Krzysztof Żurek, któremu smaki
                                Małopolski są szczególnie bliskie. Kończąc renomowaną szkołę gastronomiczną,
                                pracując w wielu uznanych restauracjach i hotelach pod okiem kulinarnych mistrzów
                                zdobywał wiedzę i cenne doświadczenie, które umiejętnie i pewną ręką przekuwa w
                                czyn, kunsztownie łącząc tradycję z nowoczesnością i zarazem będąc gwarantem
                                najwyższej jakości serwowanych dań.
                            </motion.p>
                        </Col>
                    </Row>
                </div>
            </article>
        </section>
    );
};

export default KitchenSection;