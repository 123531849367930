import React, {useEffect} from 'react';
import {Button, Col, Image, Row} from "react-bootstrap";
import Heading from "../../section-heading/Heading";
//Scroll Observer
import {useInView} from "react-intersection-observer"
import {useAnimation, motion} from "framer-motion";
import {useNavigate} from "react-router-dom";


const OfferSection = () => {

    const animation = useAnimation()
    const [aboutRef, inView] = useInView({
        triggerOnce: true,
        rootMargin: "-50px",
    })

    let navigate = useNavigate();

    useEffect(() => {
        if (inView) {
            animation.start("visible")
        }
    }, [animation, inView])


    const MotionButton = motion(Button)


    return (
        <section id="offer" className="bg-primary">
            <Heading title="oferta"/>

            <article ref={aboutRef} className="position-relative">
                <div style={{contain: "content"}}
                     className="position-absolute section-left start-0 top-0 bottom-0 w-50 d-flex">
                    <Image style={{zIndex: "1"}} className="" src="/images/media/section-frame.jpg"/>

                    <motion.img
                        className="section-img"
                        src="/images/offer/offer.jpg"
                        animate={animation}
                        initial="hidden"
                        variants={{
                            visible: {
                                clipPath: 'polygon(0% 0%,100% 0%,100% 100%,0% 100%)',
                                scale: 1,
                                transition: {duration: 0.7, ease: "easeOut"},
                            },
                            hidden: {
                                clipPath: 'polygon(0% 0%,100% 0%,100% 0%,0% 0%)',
                                scale: 1.1
                            },
                        }}
                    />
                </div>

                <div className="container">
                    <Row className="">
                        <Col lg={{span: 6, offset: 6}} style={{zIndex: "1", paddingBlock: "4rem"}}
                             className="ps-lg-5">
                            <motion.h2
                                className="text-secondary mb-4 text-uppercase"
                                animate={animation}
                                initial="hidden"
                                variants={{
                                    visible: {
                                        opacity: 1,
                                        y: 0,
                                        transition: {duration: 0.7, ease: "easeOut"},
                                    },
                                    hidden: {opacity: 0, y: 40},
                                }}
                            >
                                Chrzciny, komunie, imprezy okolicznościowe
                            </motion.h2>


                            <motion.p
                                className="mb-5"
                                animate={animation}
                                initial="hidden"
                                variants={{
                                    visible: {
                                        opacity: 1,
                                        y: 0,
                                        transition: {duration: 0.7, ease: "easeOut", delay: 0.35},
                                    },
                                    hidden: {
                                        opacity: 0,
                                        y: 40,
                                    },
                                }}
                            >
                                Wychodząc naprzeciw oczekiwaniom naszych Gości komponujemy specjalne menu
                                okolicznościowe, doradzamy przy organizacji imprez zorganizowanych takich jak
                                chrzciny, komunie, urodziny, spotkania w gronie najbliższych. Dbając o komfort
                                naszych gości, oddając do ich pełnej dyspozycji klimatyzowane sale, w nowoczesnym
                                wystroju. Nasza restauracja jest jednym z nielicznych miejsc do organizacji zarówno
                                kameralnych przyjęć jak i dużych imprez okolicznościowych. Zapewniamy miłą atmosferę
                                oraz znakomite jedzenie.
                            </motion.p>

                            <MotionButton
                                onClick={() => navigate("/offer")}
                                size="xxl"
                                variant="brand"
                                animate={animation}
                                initial="hidden"
                                variants={{
                                    visible: {
                                        opacity: 1,
                                        y: 0,
                                        transition: {duration: 0.7, ease: "easeOut", delay: 0.7},
                                    },
                                    hidden: {
                                        opacity: 0,
                                        y: 40,
                                    },
                                }}
                            >
                                zobacz naszą ofertę
                            </MotionButton>
                        </Col>
                    </Row>
                </div>
            </article>
        </section>
    );
};

export default OfferSection;