import React, {useEffect} from 'react';
import Heading from "../../components/section-heading/Heading";
import {Col, Container, Image, Row} from "react-bootstrap";
import {useAnimation, motion} from "framer-motion";
import {useInView} from "react-intersection-observer";
import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'

const OfferPage = () => {


    const animation = useAnimation()
    const [aboutRef, inView] = useInView({
        triggerOnce: true,
    })

    useEffect(() => {
        if (inView) {
            animation.start("visible")
        }
    }, [animation, inView])

    const variants = {
        visible: i => ({
            clipPath: 'polygon(0% 0%,100% 0%,100% 100%,0% 100%)',
            scale: 1,
            transition: {
                ease: "easeOut",
                duration: 0.7,
                delay: i * 0.35,
            },
        }),
        hidden: {
            clipPath: 'polygon(0% 0%,100% 0%,100% 0%,0% 0%)',
            scale: 1.1,
        },
    }

    const images = [
        {
            src: "/images/offer/offer.jpg"
        },
        {
            src: "/images/offer/offer2.jpg"
        },
        {
            src: "/images/offer/offer3.jpg"
        },
        {
            src: "/images/offer/offer4.jpg"
        },
        {
            src: "/images/offer/offer5.jpg"
        },

    ]

    return (
        <section style={{paddingTop: "122px"}} className="bg-primary">
            <Heading title="oferta"/>

            <Container>
                <Row ref={aboutRef} as="article" className="py-4">
                    <Col lg={6} style={{zIndex: "1"}}>
                        <Row xs={2} className="g-4">
                            {Array.from({length: 5}).map((_, idx) => (
                                <Col key={idx} xs={idx === 0 ? 12 : 6}>
                                    <motion.div
                                        custom={idx}
                                        animate="visible"
                                        variants={variants}
                                        initial="hidden"
                                        className="d-flex"
                                    >
                                        <Zoom overlayBgColorEnd="#2D523F">
                                            <motion.img
                                                alt={`Oferta zdjęcie ${idx+1}`}
                                                className="img-fluid"
                                                src={images[idx].src}
                                            />
                                        </Zoom>
                                    </motion.div>
                                </Col>
                            ))}
                        </Row>

                    </Col>
                    <Col className="ps-lg-5 mt-5 mt-lg-0" style={{zIndex: "1"}}>
                        <section style={{top: "160px"}} className="position-sticky">
                            <motion.h2
                                className="text-secondary mb-4 text-uppercase"
                                ref={aboutRef}
                                animate={animation}
                                initial="hidden"
                                variants={{
                                    visible: {
                                        opacity: 1,
                                        y: 0,
                                        transition: {duration: 0.7, ease: "easeOut"},
                                    },
                                    hidden: {opacity: 0, y: 40},
                                }}
                            >
                                Chrzciny, komunie, imprezy okolicznościowe
                            </motion.h2>
                            <motion.p
                                ref={aboutRef}
                                animate={animation}
                                initial="hidden"
                                variants={{
                                    visible: {
                                        opacity: 1,
                                        y: 0,
                                        transition: {duration: 0.7, ease: "easeOut", delay: 0.35},
                                    },
                                    hidden: {
                                        opacity: 0,
                                        y: 40,
                                    },
                                }}
                            >
                                Wychodząc naprzeciw oczekiwaniom naszych Gości komponujemy specjalne menu
                                okolicznościowe, doradzamy przy organizacji imprez zorganizowanych takich jak
                                chrzciny, komunie, urodziny, spotkania w gronie najbliższych. Dbając o komfort
                                naszych gości, oddając do ich pełnej dyspozycji klimatyzowane sale, w nowoczesnym
                                wystroju. Nasza restauracja jest jednym z nielicznych miejsc do organizacji zarówno
                                kameralnych przyjęć jak i dużych imprez okolicznościowych. Zapewniamy miłą atmosferę
                                oraz znakomite jedzenie.
                            </motion.p>
                        </section>
                    </Col>
                </Row>
                <div className="py-6"/>
            </Container>

            <Image style={{top: "10%"}} src="/images/media/roslinka-prawo.png"
                   className="plants position-absolute end-0"/>

        </section>
    );
};

export default OfferPage;
