import React, {useEffect, useState} from 'react';
import Menu from "./Menu";
import "./Navbar.scss"
import {Container, Image} from "react-bootstrap";
import {Link, useLocation} from "react-router-dom";
import MenuMobile from "./MenuMobile";

const Navbar = () => {

    const [pos, setPos] = useState(true)
    const location = useLocation();


    useEffect(() => {
        document.addEventListener("scroll", e => {
            let scrolled = document.scrollingElement.scrollTop;
            if (scrolled >= 150 || scrolled === null) {
                setPos(false)
            } else {
                setPos(true)
            }
        })
    }, [])


    return (
        <nav style={pos ? {height: "120px"} : {height: "100px"}}
             className={`navigation ${pos && location.pathname === "/" ? "" : "bg-primary bg-image"} d-flex align-items-center justify-content-between position-fixed start-0 end-0`}>
            <Container className="d-flex align-items-center justify-content-between h-100">

                <Link style={{height: "160px", zIndex: "1"}} to="/"
                      className="bg-primary mt-auto d-flex align-items-center px-4">
                    <Image height="100px" width="auto" src="/images/header/logo.png"/>
                </Link>

                <Menu/>
                <MenuMobile/>

            </Container>
        </nav>
    );
};

export default Navbar;