import './App.scss';
import Navbar from "./components/landing-page/header/navbar/Navbar";
import Slider from "./components/landing-page/header/slider/Slider";
import AboutSection from "./components/landing-page/about-us-section/AboutSection";
import MenuSection from "./components/landing-page/menu-section/MenuSection";
import OfferSection from "./components/landing-page/offer-section/OfferSection";
import ContactSection from "./components/landing-page/footer/ContactSection";
import {Routes, Route, BrowserRouter, useLocation} from "react-router-dom";
import OfferPage from "./pages/offer/OfferPage";
import {Image} from "react-bootstrap";
import {CSSTransition, SwitchTransition, TransitionGroup} from "react-transition-group";
import ScrollToTop from "./components/ScrollToTop/ScrollToTop";
import GalleryPage from "./pages/gallery/GalleryPage";
import ContactPage from "./pages/contact/ContactPage";
import KitchenSection from "./components/landing-page/kitchen-section/KitchenSection";

function App() {

    const location = useLocation();


    return (
        <TransitionGroup component={null}>
            <SwitchTransition mode="out-in">
                <CSSTransition key={location.pathname} classNames="fade" timeout={600}>

                    <div className="App bg-primary position-relative">

                        <ScrollToTop/>
                        <Navbar/>

                        <Routes location={location}>
                            <Route path='/'
                                   element={
                                       <>
                                           <Slider/>
                                           <AboutSection/>
                                           <MenuSection/>
                                           <OfferSection/>
                                           <KitchenSection/>
                                           <Image fluid style={{top: "60%"}} src="/images/media/roslinka-prawo.png"
                                                  className="plants position-absolute end-0"/>
                                           <Image fluid style={{top: "9%"}} src="/images/media/roslinka-prawo2.png"
                                                  className="plants position-absolute end-0"/>
                                       </>
                                   }
                            />

                            <Route path="/offer" element={<OfferPage/>}/>
                            <Route path="/gallery" element={<GalleryPage/>}/>
                            <Route path="/contact" element={<ContactPage/>}/>

                        </Routes>

                        <ContactSection/>

                        <Image fluid style={{top: "40%"}} src="/images/media/roslinka-lewo.png"
                               className="plants position-absolute start-0"/>

                    </div>


                </CSSTransition>
            </SwitchTransition>
        </TransitionGroup>
    );
}

export const Root = () => <BrowserRouter><App/></BrowserRouter>;
