import React, {useEffect} from "react";
import "./SlickCarousel.scss"
import {useAnimation} from "framer-motion";
import {useInView} from "react-intersection-observer";
import 'react-medium-image-zoom/dist/styles.css'
import {Navigation} from "swiper";
import {Swiper, SwiperSlide} from "swiper/react";
import CarouselItem from "./CorouselItem";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

const SlickCarousel = () => {

    const animation = useAnimation()
    const [aboutRef, inView] = useInView({
        triggerOnce: true,
    })

    useEffect(() => {
        if (inView) {
            animation.start("visible")
        }
    }, [animation, inView])

    return (
        <section ref={aboutRef}>

            <Swiper
                // slidesPerView={1}
                breakpoints={{
                    0: {
                        slidesPerView: 1,
                        spaceBetween: 30,
                    },
                    768: {
                        slidesPerView: 2,
                        spaceBetween: 30,
                    },
                    1200: {
                        slidesPerView: 3,
                        spaceBetween: 60,
                    },
                }}
                navigation={true}
                // loop={true}
                // pagination={true}
                draggable={true}
                modules={[Navigation]}
                className="menuSwiper"
            >
                <SwiperSlide>
                    <CarouselItem animation={animation} img="/images/menu/MENU-01.jpg"
                                  alt="MEATME - Menu główne"/>
                </SwiperSlide>
                <SwiperSlide>
                    <CarouselItem animation={animation} img="/images/menu/MENU-02.jpg"
                                  alt="MEATME - Menu przystawki"/>
                </SwiperSlide>
                <SwiperSlide>
                    <CarouselItem animation={animation} img="/images/menu/MENU-03.jpg"
                                  alt="MEATME - Menu dla dzieci"/>
                </SwiperSlide>
            </Swiper>
        </section>
    );
}

export default SlickCarousel;
