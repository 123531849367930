import React, {useEffect} from 'react';
import Heading from "../../section-heading/Heading";
import {Col, Image, Row} from "react-bootstrap";
import "./AboutSection.scss"
import {useAnimation, motion} from "framer-motion";
import {useInView} from "react-intersection-observer";

const AboutSection = () => {

    const animation = useAnimation()
    const [aboutRef, inView] = useInView({
        triggerOnce: true,
        // Giving our scrollwheel additional 300px before executing animation
        rootMargin: "-50px",
    })

    useEffect(() => {
        if (inView) {
            animation.start("visible")
        }
    }, [animation, inView])


    return (
        <section id="about">
            <Heading title="O nas"/>
            <section
                className="bg-primary"
                ref={aboutRef}
            >
                <section className="about-section position-relative">

                    <div style={{contain: "content"}} className="position-absolute section-left start-0 top-0 bottom-0 w-50 d-flex">
                        <Image style={{zIndex: "1"}} className="" src="/images/media/section-frame.jpg"/>

                        <motion.img
                            className="section-img"
                            src="/images/media/about-us.jpg"
                            animate={animation}
                            initial="hidden"
                            variants={{
                                visible: {
                                    clipPath: 'polygon(0% 0%,100% 0%,100% 100%,0% 100%)',
                                    scale: 1,
                                    transition: {duration: 0.7, ease: "easeOut"},
                                },
                                hidden: {
                                    clipPath: 'polygon(0% 0%,100% 0%,100% 0%,0% 0%)',
                                    scale: 1.1
                                },
                            }}
                        />

                    </div>

                    <div className="container">


                        <Row className="">
                            <Col lg={{span: 6, offset: 6}} style={{zIndex: "1", paddingBlock: "4rem"}}
                                 className="ps-lg-5">

                                <motion.h2
                                    className="text-secondary mb-4"
                                    animate={animation}
                                    initial="hidden"
                                    variants={{
                                        visible: {
                                            opacity: 1,
                                            y: 0,
                                            transition: {duration: 0.7, ease: "easeOut"},
                                        },
                                        hidden: {opacity: 0, y: 40},
                                    }}
                                >
                                    RESTAURACJA MEAT ME
                                </motion.h2>

                                <motion.p
                                    className="mb-5"
                                    animate={animation}
                                    initial="hidden"
                                    variants={{
                                        visible: {
                                            opacity: 1,
                                            y: 0,
                                            transition: {duration: 0.7, ease: "easeOut", delay: 0.35},
                                        },
                                        hidden: {
                                            opacity: 0,
                                            y: 40,
                                        },
                                    }}
                                >
                                    Restauracja MEAT ME to miejsce pełne pasji i dobrego jedzenia, przygotowanego z dużą
                                    starannością tak, by sprostać oczekiwaniom najbardziej wymagających smakoszy.
                                    Nietuzinkowe, zaskakujące wnętrze - architektoniczna, energetyczna fuzja kontrastów
                                    i odważnych połączeń tworzących miejską oazę pełną pozytywnej energii, zachęcającą
                                    do spotkań pełnych smaku. Panoramiczny widok na miasto z przestronnych i
                                    komfortowych tarasów zapraszających do relaksu. Lokal przyjazny rodzinom, usytuowany
                                    na 2 piętrze budynku z windą w centrum Limanowej.
                                </motion.p>
                            </Col>
                        </Row>
                    </div>


                </section>
            </section>
        </section>
    );
};

export default AboutSection;