import React, {useEffect} from 'react';
import Heading from "../../components/section-heading/Heading";
import {Col, Container, Row} from "react-bootstrap";
import {motion, useAnimation} from "framer-motion";
import Zoom from "react-medium-image-zoom";
import {useInView} from "react-intersection-observer";

const GalleryPage = () => {

    const animation = useAnimation()
    const [aboutRef, inView] = useInView({
        triggerOnce: true,
    })

    useEffect(() => {
        if (inView) {
            animation.start("visible")
        }
    }, [animation, inView])

    const variants = {
        visible: i => ({
            clipPath: 'polygon(0% 0%,100% 0%,100% 100%,0% 100%)',
            scale: 1,
            transition: {
                ease: "easeOut",
                duration: 0.7,
                delay: i * 0.35,
            },
        }),
        hidden: {
            clipPath: 'polygon(0% 0%,100% 0%,100% 0%,0% 0%)',
            scale: 1.1,
        },
    }


    const images = [
        {
            src: "/images/gallery/MEAT ME 1.jpg"
        },
        {
            src: "/images/gallery/MEAT ME 2.jpg"
        },
        {
            src: "/images/gallery/MEAT ME 3.jpg"
        },
        {
            src: "/images/gallery/MEAT ME 4.jpg"
        },
        {
            src: "/images/gallery/MEAT ME 5.jpg"
        },
        {
            src: "/images/gallery/MEAT ME 6.jpg"
        },
        {
            src: "/images/gallery/MEAT ME 7.jpg"
        },
        {
            src: "/images/gallery/MEAT ME 8.jpg"
        },
        {
            src: "/images/gallery/MEAT ME 9.jpg"
        },
        {
            src: "/images/gallery/MEAT ME 10.jpg"
        },
        {
            src: "/images/gallery/MEAT ME 11.jpg"
        },
        {
            src: "/images/gallery/MEAT ME 12.jpg"
        },
        {
            src: "/images/gallery/MEAT ME 13.jpg"
        },
        {
            src: "/images/gallery/MEAT ME 14.jpg"
        },
        {
            src: "/images/gallery/MEAT ME 15.jpg"
        },
        {
            src: "/images/gallery/MEAT ME 16.jpg"
        },
        {
            src: "/images/gallery/MEAT ME 17.jpg"
        },
        {
            src: "/images/gallery/MEAT ME 18.jpg"
        },
        {
            src: "/images/gallery/MEAT ME 19.jpg"
        },
        {
            src: "/images/gallery/MEAT ME 20.jpg"
        },
        {
            src: "/images/gallery/MEAT ME 21.jpg"
        },
        {
            src: "/images/gallery/MEAT ME 23.jpg"
        },
        {
            src: "/images/gallery/MEAT ME 26.jpg"
        },
        {
            src: "/images/gallery/MEAT ME 27.jpg"
        },
        {
            src: "/images/gallery/MEAT ME 28.jpg"
        },
        {
            src: "/images/gallery/MEAT ME 29.jpg"
        },
        {
            src: "/images/gallery/MEAT ME 22.jpg"
        },
        {
            src: "/images/gallery/MEAT ME 30.jpg"
        },
        {
            src: "/images/gallery/MEAT ME 24.jpg"
        },
        {
            src: "/images/gallery/MEAT ME 25.jpg"
        },
        {
            src: "/images/gallery/MEAT ME 31.jpg"
        },
        {
            src: "/images/gallery/MEAT ME 32.jpg"
        },
        {
            src: "/images/gallery/MEAT ME 33.jpg"
        },
        {
            src: "/images/gallery/MEAT ME 34.jpg"
        },
        {
            src: "/images/gallery/MEAT ME 35.jpg"
        },
        {
            src: "/images/gallery/MEAT ME 36.jpg"
        },
        {
            src: "/images/gallery/MEAT ME 37.jpg"
        },
        {
            src: "/images/gallery/MEAT ME 38.jpg"
        },
        {
            src: "/images/gallery/MEAT ME 39.jpg"
        },
        {
            src: "/images/gallery/MEAT ME 40.jpg"
        },
        {
            src: "/images/gallery/MEAT ME 41.jpg"
        },
        {
            src: "/images/gallery/MEAT ME 42.jpg"
        },
        {
            src: "/images/gallery/MEAT ME 43.jpg"
        },
        {
            src: "/images/gallery/MEAT ME 44.jpg"
        },
        {
            src: "/images/gallery/MEAT ME 45.jpg"
        },
        {
            src: "/images/gallery/MEAT ME 46.jpg"
        },
        {
            src: "/images/gallery/MEAT ME 47.jpg"
        },
        {
            src: "/images/gallery/MEAT ME 48.jpg"
        },
        {
            src: "/images/gallery/MEAT ME 49.jpg"
        },
        {
            src: "/images/gallery/MEAT ME 50.jpg"
        },
    ]

    return (
        <article style={{paddingTop: "122px"}} className="bg-primary text-center">
            <Heading title="Galeria"/>

            <section className="py-4">
                <Container>

                    <Row ref={aboutRef} className="g-4">
                        {Array.from({length: 4}).map((_, id1) => (
                                <Col key={id1} xs={6} xl={3}>
                                    {Array.from({length: Math.ceil(images.length / 4)}).map((_, id2) => (
                                        ((Math.ceil(images.length / 4)*id1)+id2) < images.length ?
                                        <motion.div
                                            key={id2}
                                            className="d-flex mb-4"
                                            custom={id2}
                                            animate="visible"
                                            variants={variants}
                                            initial="hidden"
                                        >
                                            <Zoom overlayBgColorEnd="#2D523F">
                                                <img
                                                    alt={`Galeria zdjęcie ${(Math.ceil(images.length / 4)*id1)+id2+1}`}
                                                    className="img-fluid"
                                                    src={images[(Math.ceil(images.length / 4)*id1)+id2].src}
                                                />
                                            </Zoom>
                                        </motion.div> : null
                                    ))}
                                </Col>
                            ))}
                    </Row>

                </Container>

            </section>
        </article>
    );
};

export default GalleryPage;
