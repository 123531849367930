import React from 'react';
import {Nav} from "react-bootstrap";
import {MenuData} from "./MenuData";
import {LinkContainer} from 'react-router-bootstrap'
import {useLocation, useNavigate} from "react-router-dom";
// import {MouseContext} from "../../../../context/mouse-context";
// import {useAnimation, motion} from "framer-motion";

const Menu = () => {

    // const {cursorType, cursorChangeHandler} = useContext(MouseContext);

    let navigate = useNavigate();
    let location = useLocation();

    const ScrollTo = (id) => {
        const redirect = () => {
            if (location.pathname !== "/") navigate("/")
        }

        async function asyncCall() {
            await redirect();

            setTimeout(() => {
                const yOffset = -95;
                const element = document.getElementById(id);
                const y = element.getBoundingClientRect().top + window.scrollY + yOffset;
                window.scrollTo({top: y, behavior: 'smooth'});
            }, location.pathname !== "/" ? 700 : 0);

        }

        asyncCall();
    }


    // const container = {
    //     hidden: {opacity: 1, scale: 0},
    //     visible: {
    //         opacity: 1,
    //         scale: 1,
    //         transition: {
    //             delayChildren: 0.3,
    //             staggerChildren: 0.2
    //         }
    //     }
    // };
    //
    // const item = {
    //     hidden: {y: 20, opacity: 0},
    //     visible: {
    //         y: 0,
    //         opacity: 1
    //     }
    // };

    // const MotionNav = motion(Nav)
    // const MotionNavItem = motion(Nav.Item)

    return (
        <Nav
            style={{zIndex: "1"}}
            as="ul"
            className="d-none d-xl-flex align-items-center h-100"
        >
            {MenuData.map((i, k) => {
                return (
                    <Nav.Item
                        // variants={item}
                        // onMouseEnter={() => cursorChangeHandler("hovered")}
                        // onMouseLeave={() => cursorChangeHandler("")}
                        as="li"
                        key={k}
                        className="h-100 d-flex align-items-center"
                    >
                        {i.title === "O nas" || i.title === "Menu" || i.title === "Kuchnia" ?
                            <div
                                className="h-100" onClick={() => ScrollTo(i.hrf)}>
                                <Nav.Link className="h-100 d-flex align-items-center">{i.title}</Nav.Link>
                            </div> :
                            <LinkContainer to={i.hrf}>
                                <Nav.Link className="h-100 d-flex align-items-center">{i.title}</Nav.Link>
                            </LinkContainer>
                        }

                    </Nav.Item>
                )
            })}
        </Nav>
    );
};

export default Menu;