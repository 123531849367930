export const MenuData = [
    {
        title: "O nas",
        hrf: "about",
    },
    {
        title: "Menu",
        hrf: "menu"
    },
    {
        title: "Galeria",
        hrf: "/gallery"
    },
    {
        title: "Oferta",
        hrf: "/offer"
    },
    {
        title: "Kuchnia",
        hrf: "kitchen"
    },
    {
        title: "Kontakt",
        hrf: "/contact"
    },

]