import React, {useCallback, useState} from 'react';
import {motion} from "framer-motion";
import {Controlled as ControlledZoom} from "react-medium-image-zoom";

const CarouselItem = ({animation, img, alt}) => {

    const [isZoomed, setIsZoomed] = useState(false)

    const handleZoomChange = useCallback(shouldZoom => {
        setIsZoomed(shouldZoom)
    }, [])


    return (
        <motion.div
            animate={animation}
            initial="hidden"
            variants={{
                visible: {
                    clipPath: 'polygon(0% 0%,100% 0%,100% 100%,0% 100%)',
                    scale: 1,
                    transition: {duration: 0.7, ease: "easeOut"},
                },
                hidden: {
                    clipPath: 'polygon(0% 0%,100% 0%,100% 0%,0% 0%)',
                    scale: 1.1
                },
            }}
        >
            <ControlledZoom
                isZoomed={isZoomed} onZoomChange={handleZoomChange}
                overlayBgColorEnd="#2D523F"
            >
                <>
                    <div
                        style={{zIndex: "1"}}
                        className="position-absolute d-flex gap-2 top-0 end-0 m-3">
                        <a href={img} className="icon-download"
                           download={alt} title="pobierz menu">
                            <svg id="Group_1" data-name="Group 1"
                                 xmlns="http://www.w3.org/2000/svg" width="60" height="60"
                                 viewBox="0 0 60 60">
                                <circle id="Ellipse_3" data-name="Ellipse 3" cx="30" cy="30"
                                        r="30" transform="translate(60 60) rotate(180)"
                                        fill="#fff"/>
                                <path id="Union_1" data-name="Union 1"
                                      d="M0,15.341H11.455V0h6.459V15.341H29.368L14.684,32.279Z"
                                      transform="translate(15.19 10.698)"
                                      fill="rgba(45,82,63,0.3)"/>
                                <rect id="Rectangle_1" data-name="Rectangle 1" width="29"
                                      height="3" transform="translate(15 46)"
                                      fill="rgba(45,82,63,0.3)"/>
                            </svg>
                        </a>
                        <div onClick={() => setIsZoomed(!isZoomed)} className="icon-download"
                             title="powiększ">
                            <svg id="Group_2" data-name="Group 2" xmlns="http://www.w3.org/2000/svg"
                                 width="60" height="60" viewBox="0 0 60 60">
                                <circle id="Ellipse_3" data-name="Ellipse 3" cx="30" cy="30" r="30"
                                        transform="translate(60 60) rotate(180)" fill="#fff"/>
                                <path id="Union_10" data-name="Union 10"
                                      d="M0,14.021V0H14.021V5.679H5.679v8.341Z"
                                      transform="translate(13.757 13.318)" fill="rgba(45,82,63,0.3)"/>
                                <path id="Union_11" data-name="Union 11"
                                      d="M8.341,14.021V5.679H0V0H14.021V14.021Z"
                                      transform="translate(32.222 13.318)" fill="rgba(45,82,63,0.3)"/>
                                <path id="Union_9" data-name="Union 9"
                                      d="M0,14.021V0H5.679V8.341h8.341v5.679Z"
                                      transform="translate(13.757 32.661)" fill="rgba(45,82,63,0.3)"/>
                                <path id="Union_12" data-name="Union 12"
                                      d="M0,14.021V8.341H8.341V0h5.679V14.021Z"
                                      transform="translate(32.222 32.661)" fill="rgba(45,82,63,0.3)"/>
                            </svg>

                        </div>
                    </div>
                    <img
                        alt={alt}
                        className="img-fluid"
                        src={img}
                    />
                </>
            </ControlledZoom>
        </motion.div>
    );
};

export default CarouselItem;
