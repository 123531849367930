import React, {useEffect} from 'react';
import "./Heading.scss"
import {useAnimation, motion} from "framer-motion";
import {useInView} from "react-intersection-observer";

const Heading = ({title}) => {


    const animation = useAnimation()
    const [aboutRef, inView] = useInView({
        triggerOnce: true,
        // Giving our scrollwheel additional 300px before executing animation
        // rootMargin: "-150px",
    })

    useEffect(() => {
        if (inView) {
            animation.start("visible")
        }
    }, [animation, inView])

    return (
        <header className="section-heading bg-image ">
            <div  className="container d-flex justify-content-between align-items-center py-5 section-heading-item">

                <motion.div
                    className="h1 text-uppercase text-secondary text-nowrap"
                    ref={aboutRef}
                    animate={animation}
                    initial="hidden"
                    variants={{
                        visible: {
                            opacity: 1,
                            x: 0,
                            transition: {duration: 0.7, ease: "easeOut"},
                        },
                        hidden: {
                            opacity: 0,
                            x: -40
                        },
                    }}
                >
                    {title}
                </motion.div>
                <motion.hr
                    style={{height: "2px"}}
                    className="w-100 ms-4 bg-secondary opacity-100"
                    ref={aboutRef}
                    animate={animation}
                    initial="hidden"
                    variants={{
                        visible: {
                            clipPath: 'polygon(0% 0%,100% 0%,100% 100%,0% 100%)',
                            transition: {duration: 0.7, ease: "easeOut", delay: 0.7},
                        },
                        hidden: {
                            clipPath: 'polygon(0 0, 0 0, 0 100%, 0 100%)',
                        },
                    }}
                />
                <motion.img
                    src="/images/media/heading-ornament.svg"
                    ref={aboutRef}
                    animate={animation}
                    initial="hidden"
                    variants={{
                        visible: {
                            clipPath: 'polygon(0% 0%,100% 0%,100% 100%,0% 100%)',
                            transition: {duration: 0.7, ease: "easeOut", delay: 1.4},
                        },
                        hidden: {
                            clipPath: 'polygon(0 0, 0 0, 0 100%, 0 100%)',
                        },
                    }}
                />
            </div>
        </header>
    );
};

export default Heading;