import React from 'react';
import Heading from "../../section-heading/Heading";
import SlickCarousel from "../../carousel/SlickCarousel";
import {Container} from "react-bootstrap";

const MenuSection = () => {
    return (
        <section id="menu">
            <Heading title="menu"/>

            <article className="py-5 bg-primary">
                <Container>
                    <SlickCarousel/>
                </Container>
            </article>

        </section>
    );
};

export default MenuSection;